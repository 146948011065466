const styles = (theme) => ({
  actionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bodyContainer: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    height: '100%',
  },
  footerContainer: {
    flex: '0 0 40px',
  },
  rating: {
    marginRight: '3px',
  },
  reviewsPlaceholder: {
    opacity: '.7',
  },
  carModelName: {
    marginTop: '10px',
  },
  btnEdtListings: {
    borderTop: `1px solid ${theme.palette.border.main}`,
    borderRadius: 0,
    '&:hover': {
      color: theme.palette.background.main,
      backgroundColor: theme.palette.primary.main,
    },
  },
});

export default styles;
