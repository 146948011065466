import PropTypes from 'prop-types';

const customerPropType = PropTypes.shape({
  id: PropTypes.string,
  address: PropTypes.object,
  firstName: PropTypes.string,
  photo: PropTypes.string,
  about: PropTypes.string,
  createdDate: PropTypes.string,
  averageRating: PropTypes.string,
  reviewsCount: PropTypes.number,
  licenceVerified: PropTypes.bool,
  phoneVerified: PropTypes.bool,
});

const reviewsPropType = PropTypes.shape({
  asOwner: PropTypes.array,
  asRenter: PropTypes.array,
});

export default {
  customerPropType,
  reviewsPropType,
};
