import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { Link } from '@material-ui/core';

const VehicleLink = ({ id, shortName, query, children, ...rest }) => (
  <Link to={`/vehicles/${shortName}/${id}${query}`} underline="none" component={NavLink} {...rest}>
    {children}
  </Link>
);

VehicleLink.propTypes = {
  id: PropTypes.string,
  shortName: PropTypes.string,
  query: PropTypes.string,
  children: PropTypes.node,
};

VehicleLink.defaultProps = {
  id: '',
  shortName: '',
  query: '',
  children: null,
};

export default VehicleLink;
