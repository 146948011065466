import _ from 'lodash';
import moment from 'moment';

import { CustomerRoles, LicenceStatus } from '../enums';

export const customerHasOwnerRole = (customer) => {
  if (customer?.roles) {
    return customer.roles.includes(CustomerRoles.owner);
  }

  return false;
};

export const customerHasAdminRole = (customer) => {
  if (customer?.roles) {
    return customer.roles.includes(CustomerRoles.admin);
  }

  return false;
};

export const getCustomerId = (customer) => customer?.id || '';

export const getPayoutsRequiredFieldsStatus = (customer) => ({
  fullName: !_.isEmpty(customer.firstName) && !_.isEmpty(customer.lastName),
  address: !_.isEmpty(customer.address),
  dateOfBirth: moment(customer.dateOfBirth).isValid(),
  phone: !_.isEmpty(customer.phone),
  licence:
    !_.isEmpty(customer.licence) &&
    customer.licence.status !== LicenceStatus.noLicence &&
    customer.licence.status !== LicenceStatus.expired,
});

export const getStripeAccount = (customer) =>
  (customer && {
    id: customer.stripeConnectedAccounts.length > 0 ? customer.stripeConnectedAccounts[0].id : null,
    birthDate: moment(customer.dateOfBirth),
    isBusiness: !!(customer.business.businessNumber || customer.business.businessName),
    businessNumber: customer.business.businessNumber || '',
    businessName: customer.business.businessName || '',
    gstRegistered: customer.business.gstRegistered || false,
  }) ||
  {};
