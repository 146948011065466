import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { RatingDetails } from '../../../base';

const CarRating = ({ averageRating, reviewsCount, showReviewsCount, loading, className }) => {
  const carRatingSkeletonJsx = <Skeleton width="75%" />;

  const carRatingJsx = (
    <Box display="flex" alignItems="center" className={className}>
      <RatingDetails
        renderCount={() => reviewsCount}
        value={averageRating}
        reviewsCount={reviewsCount}
        showReviewsCount={showReviewsCount}
      />
    </Box>
  );

  return (
    <Box display="flex" alignItems="center">
      {loading ? carRatingSkeletonJsx : carRatingJsx}
    </Box>
  );
};

CarRating.propTypes = {
  averageRating: PropTypes.number,
  reviewsCount: PropTypes.number,
  showReviewsCount: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

CarRating.defaultProps = {
  averageRating: 0,
  reviewsCount: 0,
  showReviewsCount: true,
  loading: false,
  className: '',
};

export default CarRating;
