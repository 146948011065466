const styles = () => ({
  total: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  totalTypography: {
    textDecoration: 'underline',
    textUnderlineOffset: '4px',
    fontSize: '16px',
    fontWeight: 500,
  },
  totalWithDiscountTypography: {
    fontSize: '14px',
    fontWeight: 400,
    textDecoration: 'line-through',
    marginRight: '4px',
  },
});

export default styles;
