const styles = (theme) => ({
  cardContainer: {
    height: '100%',
  },
  actionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  footerContainer: {
    padding: '10px 20px',
  },
  carModelName: {
    ...theme.typography.h5,
  },
});

export default styles;
