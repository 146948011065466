import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import _ from 'lodash';

import {
  Box,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';

import { LinkButton } from 'evee-ui-common/components/base';
import { SearchVehicleCard } from 'evee-ui-common/components/cards';
import { getHomeUrl } from 'evee-ui-common/utils';

import useRegion from 'store/modules/app/hooks/useRegion';
import SearchVehicleLink from '../SearchVehicleLink';
import ResultsPagination from './ResultsPagination';
import styles from './styles';

const SearchWidget = ({
  vehicles,
  currentPage,
  totalPages,
  noResults,
  query,
  loading,
  classes,
  onLoad,
  onChangePage,
  onSetQueryFilters,
  onSetQuery,
}) => {
  const { region } = useRegion();
  const location = useLocation();
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleSeeAllListings = () => {
    window.location.href = `${getHomeUrl(region)}/search${query}`;
  };

  const vehicleCardsJsx = (
    <Grid container spacing={2}>
      {vehicles.slice(0, 4).map((vehicle, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <SearchVehicleCard
            key={vehicle.id}
            vehicle={vehicle}
            VehicleLink={SearchVehicleLink}
            classes={{ baseContainer: classes.cardContainer }}
          />
        </Grid>
      ))}
    </Grid>
  );

  const noResultsJsx = (
    <Grid item>
      <Box display="flex" flexDirection="column" marginTop="50px">
        <Typography align="center" variant="h3">
          No results
        </Typography>
        <Typography align="center">
          Sorry, but we didn&apos;t find any items that matches your query.
        </Typography>
      </Box>
    </Grid>
  );

  const listViewSkeletonJsx = (
    <Grid container justifyContent={noResults ? 'center' : 'space-between'} spacing={2}>
      {_.range(0, 4).map((v, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <SearchVehicleCard
            loading
            key={v.id}
            VehicleLink={Box}
            classes={{ baseContainer: classes.cardContainer }}
          />
        </Grid>
      ))}
    </Grid>
  );

  const listContentJsx = (
    <Grid container justifyContent={noResults ? 'center' : 'flex-start'}>
      <Grid item xs={12}>
        {noResults ? noResultsJsx : vehicleCardsJsx}
      </Grid>
    </Grid>
  );

  useEffect(() => {
    let queryParams = location.search;
    const scriptElement = document.getElementById('search-results');
    if (scriptElement) {
      queryParams = `?${scriptElement.getAttribute('data-query')}`;
    }

    if (queryParams) {
      const params = qs.parse(queryParams, {
        ignoreQueryPrefix: true,
      });

      onSetQuery(queryParams);
      onSetQueryFilters(params);
    }

    onLoad();
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="left">
      {loading && vehicles.length === 0 && !noResults ? listViewSkeletonJsx : listContentJsx}
      <LinkButton
        text="Show all cars"
        onClick={handleSeeAllListings}
        style={{ marginTop: theme.spacing(2), width: isTabletOrMobile ? '100%' : '120px' }}
      />
    </Box>
  );
};

SearchWidget.propTypes = {
  vehicles: PropTypes.arrayOf(PropTypes.object),
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  loading: PropTypes.bool,
  noResults: PropTypes.bool,
  query: PropTypes.string,
  classes: PropTypes.shape({
    cardContainer: PropTypes.string.isRequired,
  }).isRequired,
  onLoad: PropTypes.func,
  onChangePage: PropTypes.func,
  onSetQueryFilters: PropTypes.func,
  onSetQuery: PropTypes.func,
};

SearchWidget.defaultProps = {
  vehicles: [],
  currentPage: 0,
  totalPages: 0,
  loading: false,
  noResults: false,
  query: '',
  onLoad: () => {},
  onChangePage: () => {},
  onSetQueryFilters: () => {},
  onSetQuery: () => {},
};

export default withStyles(styles)(SearchWidget);
