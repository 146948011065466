import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const DiscountTag = ({ totalDiscount, classes }) => (
  <Box component="span" className={classes.discount}>
    <LocalOfferOutlinedIcon fontSize="small" style={{ marginRight: '4px' }} />
    <Typography className={classes.discountTypography}>Save {totalDiscount}</Typography>
  </Box>
);

DiscountTag.propTypes = {
  totalDiscount: PropTypes.string,
  classes: PropTypes.shape({
    total: PropTypes.string,
    totalTypography: PropTypes.string,
    totalWithDiscountTypography: PropTypes.string,
    discount: PropTypes.string,
    discountTypography: PropTypes.string,
  }).isRequired,
};

DiscountTag.defaultProps = {
  totalDiscount: '',
};

export default withStyles(styles)(DiscountTag);
