import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';

import {
  DeclarationStatus,
  LicenceStatus,
  PaymentMethodStatus,
  PhoneVerificationStatus,
} from '../enums';

import listing from './listing';

const requiredMsg = 'required';

const phoneNumberSchema = yup
  .object()
  .shape({
    code: yup.string(),
    number: yup.string(),
  })
  .test('isValidPhone', 'required', (value) => value?.code && value?.number);

const licenceSchema = yup
  .object()
  .shape({
    status: yup.string(),
    countryCode: yup.string().nullable(),
    number: yup.string().nullable(),
    verifiedTill: yup.string().nullable(),
  })
  .test(
    'isValidLicence',
    'verify your licence please',
    (value) => value.status !== LicenceStatus.noLicence && value.status !== LicenceStatus.expired,
  );

const basketSchema = yup.object().shape({
  dateOfBirth: yup
    .string()
    .nullable()
    .test('is valid date', requiredMsg, (v) => moment(v).isValid()),
  address: listing.shortAddressSchema,
  phone: phoneNumberSchema,
  phoneStatus: yup
    .string()
    .test(
      'phone is verified',
      'phone must be verified',
      (v) => v === PhoneVerificationStatus.verified,
    ),
  licence: licenceSchema,
  declarationStatus: yup
    .string()
    .test(
      'is declaration verified',
      'declaration must be verified',
      (v) => v === DeclarationStatus.verified,
    ),
  message: yup.string().required(requiredMsg),
  defaultPaymentMethod: yup
    .object()
    .test(
      'is card verified',
      'Add at least one verified payment method',
      (v) => v && v.id && v.status === PaymentMethodStatus.verified,
    )
    .nullable()
    .required(requiredMsg),
  emailVerified: yup.bool().test('email is verified', 'email must be verified', (v) => v === true),
});

const yourInfoSchema = basketSchema.pick([
  'dateOfBirth',
  'address',
  'phone',
  'phoneStatus',
  'photo',
  'emailVerified',
]);

const licenceAndDeclarationSchema = basketSchema.pick(['licence', 'declarationStatus']);

const paymentSchema = basketSchema.pick(['message', 'defaultPaymentMethod']);

const parse = (basketRaw) => {
  if (!basketRaw) {
    return null;
  }

  return {
    ...basketRaw,
    totalRental: _.sumBy(basketRaw.bookingDaysPrices, 'priceBeforeDiscount'),
    totalDiscount: _.sumBy(basketRaw.bookingDaysPrices, 'discountAmount'),
    id: basketRaw._id,
  };
};

const serialize = (basket) => ({
  _id: basket.id,
  ...basket,
});

export default {
  phoneNumberSchema,
  licenceSchema,
  basketSchema,
  yourInfoSchema,
  licenceAndDeclarationSchema,
  paymentSchema,
  parse,
  serialize,
};
