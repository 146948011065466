import { formatDateApiUtc } from '../../../utils';
import { vehicle as vehicleModel } from '../../../models';

import makeAxiosRequest from '../utils/makeAxiosRequest';
import makeRequest from '../utils/makeRequest';

const listingsUrl = '/listings';
const draftsUrl = '/drafts';

const formatDate = (date) => formatDateApiUtc(date.startOf('day'));

export const get = async (id) =>
  makeRequest({
    method: 'get',
    url: `${listingsUrl}/${id}`,
  }).then(vehicleModel.parse);

export const getAll = async () =>
  makeRequest({
    method: 'get',
    url: listingsUrl,
  }).then((vehiclesRaw) => vehiclesRaw.map(vehicleModel.parse));

export const create = async () =>
  makeRequest({
    method: 'post',
    url: `${listingsUrl}`,
  }).then(vehicleModel.parse);

export const getLastDraft = async () =>
  makeRequest({
    method: 'get',
    url: `${listingsUrl}${draftsUrl}/last`,
  }).then(vehicleModel.parse);

export const saveDraft = async (id, listing) =>
  makeRequest({
    method: 'put',
    url: `${listingsUrl}${draftsUrl}/${id}`,
    body: vehicleModel.serialize(listing),
  });

export const publish = async (id, listing) =>
  makeRequest({
    method: 'put',
    url: `${listingsUrl}/${id}/publish`,
    body: vehicleModel.serialize(listing),
  });

export const remove = async (id) =>
  makeRequest({
    method: 'delete',
    url: `${listingsUrl}/${id}`,
  });

export const hide = async (id) =>
  makeRequest({
    method: 'put',
    url: `${listingsUrl}/${id}/hide`,
  });

export const getOccupations = async ({ vehicleId, month, year }) =>
  makeRequest({
    method: 'get',
    url: `${listingsUrl}/${vehicleId}/occupations`,
    params: { month, year },
  });

export const addOccupation = async ({ vehicleId, from, to }) =>
  makeRequest({
    method: 'post',
    url: `${listingsUrl}/${vehicleId}/occupations`,
    body: { from: formatDate(from), to: formatDate(to) },
  });

export const deleteOccupation = async ({ vehicleId, from, to }) =>
  makeRequest({
    method: 'delete',
    url: `${listingsUrl}/${vehicleId}/occupations`,
    body: { from: formatDate(from), to: formatDate(to) },
  });

export const uploadFile = async (url, type, data) =>
  makeAxiosRequest({
    method: 'put',
    maxBodyLength: Infinity,
    url,
    headers: {
      'x-api-key': process.env.REACT_APP_FILES_API_KEY,
      'Content-Type': type,
    },
    body: data,
  });

export const removeFile = async (url) =>
  makeAxiosRequest({
    method: 'delete',
    url,
    headers: {
      'x-api-key': process.env.REACT_APP_FILES_API_KEY,
    },
  });

export const getCustomPrices = async (vehicleId, { month, year }) =>
  makeRequest({
    method: 'get',
    url: `${listingsUrl}/${vehicleId}/customPrices`,
    params: { month, year },
  });

export const createCustomPrice = async (vehicleId, { from, to, price }) =>
  makeRequest({
    method: 'post',
    url: `${listingsUrl}/${vehicleId}/customPrices`,
    body: { from: formatDate(from), to: formatDate(to), price },
  });

export const updateCustomPrice = async (vehicleId, customPriceId, priceRange) =>
  makeRequest({
    method: 'put',
    url: `${listingsUrl}/${vehicleId}/customPrices/${customPriceId}`,
    body: {
      from: formatDate(priceRange.from),
      to: formatDate(priceRange.to),
      price: priceRange.price,
    },
  });

export const deleteCustomPrice = async (vehicleId, customPriceId) =>
  makeRequest({
    method: 'delete',
    url: `${listingsUrl}/${vehicleId}/customPrices/${customPriceId}`,
  });

export const attachDocument = async (vehicleId, url, type) =>
  makeRequest({
    method: 'post',
    url: `${listingsUrl}/${vehicleId}/document/${type}`,
    body: {
      url,
    },
  });

export const removeDocument = async (vehicleId, type) =>
  makeRequest({
    method: 'delete',
    url: `${listingsUrl}/${vehicleId}/document/${type}`,
  });
