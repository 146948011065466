import PropTypes from 'prop-types';
import React from 'react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const FeatureTag = ({ freeAirportDelivery, unlimitedMileage, freeFastCharging, classes }) => {
  const getLabel = () => {
    if (freeAirportDelivery) {
      return 'Free airport delivery';
    }

    if (unlimitedMileage) {
      return 'Unlimited mileage';
    }

    if (freeFastCharging) {
      return 'Free fast charging';
    }

    return '';
  };

  const tagLabel = getLabel();

  return tagLabel ? (
    <Typography variant="body2" className={classes.label}>
      {tagLabel}
    </Typography>
  ) : null;
};

FeatureTag.propTypes = {
  freeAirportDelivery: PropTypes.bool,
  unlimitedMileage: PropTypes.bool,
  freeFastCharging: PropTypes.bool,
  classes: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
};

FeatureTag.defaultProps = {
  freeAirportDelivery: false,
  unlimitedMileage: false,
  freeFastCharging: false,
};

export default withStyles(styles)(FeatureTag);
