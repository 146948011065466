import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import DiscountTag from '../../utils/DiscountTag';
import styles from './styles';

const TotalPayout = ({ total, totalBeforeDiscount, totalDiscount, loading, classes }) => {
  const payoutJsx = (
    <>
      <Box className={classes.total}>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box>
            {totalDiscount && (
              <Typography
                component="span"
                color="textSecondary"
                className={classes.totalWithDiscountTypography}
              >
                {totalBeforeDiscount}
              </Typography>
            )}

            <Typography component="span" className={classes.totalTypography}>
              {total} total
            </Typography>
          </Box>

          {totalDiscount && <DiscountTag totalDiscount={totalDiscount} />}
        </Box>
      </Box>
    </>
  );

  return (
    <Box display="flex" alignItems={loading ? 'flex-end' : 'baseline'} justifyContent="flex-start">
      {loading ? (
        <Box display="flex" flexDirection="column" width="100%" marginBottom="8px">
          <Skeleton height="3em" width="40%" />
        </Box>
      ) : (
        payoutJsx
      )}
    </Box>
  );
};

TotalPayout.propTypes = {
  total: PropTypes.string,
  totalBeforeDiscount: PropTypes.string,
  totalDiscount: PropTypes.string,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    total: PropTypes.string,
    totalTypography: PropTypes.string,
    totalWithDiscountTypography: PropTypes.string,
    discount: PropTypes.string,
    discountTypography: PropTypes.string,
  }).isRequired,
};

TotalPayout.defaultProps = {
  total: '',
  totalBeforeDiscount: '',
  totalDiscount: '',
  loading: false,
};

export default withStyles(styles)(TotalPayout);
