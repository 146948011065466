const styles = (theme) => ({
  label: {
    position: 'absolute',
    top: '16px',
    left: '16px',
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadiusSmall,
    backgroundColor: theme.palette.common.white,
    fontWeight: 500,
  },
});

export default styles;
