import { extraPayment as extraPaymentModel } from '../../../models';

import errorService from '../../errorService';
import makeRequest from '../utils/makeRequest';

const extraPaymentsUrl = '/extraPayments';

export const create = async (draft, currency) =>
  makeRequest({
    method: 'post',
    url: `${extraPaymentsUrl}`,
    params: { currency },
    body: extraPaymentModel.serialize(draft),
    errorWrapper: errorService.extraPayments.createErr,
  }).then(extraPaymentModel.parse);

export const get = async (bookingId, currency) =>
  makeRequest({
    method: 'get',
    url: `${extraPaymentsUrl}`,
    params: { bookingId, currency },
    errorWrapper: errorService.extraPayments.loadingErr,
  }).then((response) => response.map((extraPayment) => extraPaymentModel.parse(extraPayment)));

export const getDraft = async (bookingId) =>
  makeRequest({
    method: 'get',
    url: `${extraPaymentsUrl}/draft`,
    params: { bookingId },
    errorWrapper: errorService.extraPayments.loadingDraftErr,
  }).then(extraPaymentModel.parseDraft);

export const deleteDraft = async (draftId) =>
  makeRequest({
    method: 'delete',
    url: `${extraPaymentsUrl}/${draftId}`,
    errorWrapper: errorService.extraPayments.deleteDraftErr,
  });

export const uploadFile = async (bookingId, draftId, file) => {
  const formData = new FormData();
  formData.append('document', new Blob([file.data], { type: file.type }), file.name);

  return makeRequest({
    method: 'post',
    url: `${extraPaymentsUrl}/uploadDocument`,
    params: draftId ? { bookingId, draftId } : { bookingId },
    body: formData,
    errorWrapper: errorService.extraPayments.uploadErr,
  }).then(extraPaymentModel.parseDraft);
};

export const deleteFile = async (draftId, key) =>
  makeRequest({
    method: 'delete',
    url: `${extraPaymentsUrl}/${draftId}/deleteDocument`,
    params: { photoKey: key },
    errorWrapper: errorService.extraPayments.deleteErr,
  });

export const cancel = async (extraPaymentId, currency) =>
  makeRequest({
    method: 'put',
    url: `${extraPaymentsUrl}/${extraPaymentId}/cancel`,
    params: {
      currency,
    },
    errorWrapper: errorService.extraPayments.cancelErr,
  }).then(extraPaymentModel.parse);

export const decline = async (extraPaymentId, currency) =>
  makeRequest({
    method: 'put',
    url: `${extraPaymentsUrl}/${extraPaymentId}/decline`,
    params: {
      currency,
    },
    errorWrapper: errorService.extraPayments.declineErr,
  }).then(extraPaymentModel.parse);

export const accept = async (extraPaymentId, card, currency) =>
  makeRequest({
    method: 'put',
    url: `${extraPaymentsUrl}/${extraPaymentId}/accept`,
    params: {
      currency,
    },
    body: {
      card,
    },
    errorWrapper: errorService.extraPayments.acceptErr,
  }).then(extraPaymentModel.parse);

export default {
  create,
  get,
  getDraft,
  deleteDraft,
  uploadFile,
  deleteFile,
  cancel,
  decline,
  accept,
};
