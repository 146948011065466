const styles = (theme) => ({
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.common.white,
  },
});

export default styles;
