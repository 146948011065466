import PropTypes from 'prop-types';
import React from 'react';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import { vehicle as vehicleTypes } from '../../../../types';

import CarModel from '../CarModel';
import CarRating from '../CarRating';
import styles from './styles';

const ModelAndRating = ({ vehicle, hideRating, loading, classes }) => {
  const { make, model, badge, year, reviewsCount, tripsCount, averageRating } = vehicle;

  return loading ? (
    <Box display="flex" justifyContent="space-between">
      <Skeleton width="50%" height="2em" />
      <Skeleton width="20%" height="2em" />
    </Box>
  ) : (
    <Box className={classes.container}>
      <CarModel
        make={make}
        model={model}
        badge={badge}
        year={year}
        classes={{ name: classes.carModel }}
      />

      {!hideRating && (
        <CarRating
          averageRating={averageRating}
          reviewsCount={reviewsCount}
          tripsCount={tripsCount}
        />
      )}
    </Box>
  );
};

ModelAndRating.propTypes = {
  vehicle: vehicleTypes.vehicle,
  hideRating: PropTypes.bool,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    container: PropTypes.string,
    carModel: PropTypes.string,
  }).isRequired,
};
ModelAndRating.defaultProps = {
  vehicle: {},
  hideRating: false,
  loading: false,
};

export default withStyles(styles)(ModelAndRating);
