import PropTypes from 'prop-types';
import React from 'react';

import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const VehicleCardBase = React.forwardRef(({ children, square, classes, ...rest }, ref) => (
  <Card ref={ref} elevation={0} square={square} className={classes.container} {...rest}>
    {children}
  </Card>
));

VehicleCardBase.propTypes = {
  children: PropTypes.node,
  square: PropTypes.bool,
  classes: PropTypes.shape({ container: PropTypes.string }).isRequired,
};

VehicleCardBase.defaultProps = { children: null, square: false };

export default withStyles(styles)(VehicleCardBase);
