import { chat as chatModel } from '../../../models';

import errorService from '../../errorService';
import makeAxiosRequest from '../utils/makeAxiosRequest';
import makeRequest from '../utils/makeRequest';

const chatUrl = '/chats';

export const getChats = async (role, page = 0, size = 4) =>
  makeRequest({
    method: 'get',
    url: `${chatUrl}`,
    params: { role, page, size },
    errorWrapper: errorService.chat.loadErr,
  }).then((result) => chatModel.parseChats(result));

export const getMessages = async (chatId) =>
  makeRequest({
    method: 'get',
    url: `${chatUrl}/${chatId}/messages`,
    errorWrapper: errorService.chat.loadErr,
  });

export const sendMessage = async (chatId, message, files) =>
  makeRequest({
    method: 'post',
    url: `${chatUrl}/${chatId}/messages`,
    body: {
      message,
      files,
    },
    errorWrapper: errorService.chat.loadErr,
  });

export const deleteMessage = async (chatId, id) =>
  makeRequest({
    method: 'delete',
    url: `${chatUrl}/${chatId}/messages/${id}`,
    body: {
      chatId,
      id,
    },
    errorWrapper: errorService.chat.loadErr,
  });

export const uploadFile = async (url, type, data) =>
  makeAxiosRequest({
    method: 'put',
    maxBodyLength: Infinity,
    url,
    headers: {
      'x-api-key': process.env.REACT_APP_FILES_API_KEY,
      'Content-Type': type,
    },
    body: data,
    errorWrapper: errorService.chat.loadErr,
  });

export const deleteFile = async (url, type) =>
  makeAxiosRequest({
    method: 'delete',
    url,
    headers: {
      'x-api-key': process.env.REACT_APP_FILES_API_KEY,
      'Content-Type': type,
    },
    errorWrapper: errorService.chat.loadErr,
  });
