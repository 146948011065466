/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';
import React from 'react';

import defaultImage from '../../images/default_image.jpg';

const Image = ({ src, alt, ...rest }) => {
  const handleImageError = ({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src = defaultImage;
    currentTarget.srcset = '';
    currentTarget.sizes = '';
  };

  return <img src={src} alt={alt} onError={handleImageError} {...rest} />;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

Image.defaultProps = {};

export default Image;
