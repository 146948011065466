import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '../../base';

import CardSection from '../utils/CardSection';
import VehicleCardBase from '../utils/VehicleCardBase';

import CarIcon from './icons/Car';
import styles from './styles';

const NewListingCard = ({ classes }) => (
  <VehicleCardBase classes={{ container: classes.listCar }}>
    <CardSection fullHeight>
      <Box marginTop="24px">
        <CarIcon className={classes.carIcon} />

        <Typography gutterBottom variant="h6" align="left">
          Want to add another car?
        </Typography>

        <Typography variant="body1" align="left">
          Add cars and earn more.
        </Typography>
      </Box>
    </CardSection>
    <CardSection>
      <Button
        text="List your car"
        component={NavLink}
        to="/listing/process"
        style={{ width: '60%' }}
      />
    </CardSection>
  </VehicleCardBase>
);

NewListingCard.propTypes = {
  classes: PropTypes.shape({
    carIcon: PropTypes.string.isRequired,
    listCar: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(NewListingCard);
