import PropTypes from 'prop-types';

const paymentMethod = PropTypes.shape({
  id: PropTypes.string,
  brand: PropTypes.string,
  expireMonth: PropTypes.number,
  expireYear: PropTypes.number,
  last4: PropTypes.string,
  isDefault: PropTypes.bool,
});

export default {
  paymentMethod,
};
