import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const CarModel = ({ name, make, model, badge, year, loading, classes, ...rest }) => {
  const vehicleName = name || `${make} ${model}`;
  const descriptionSubtitle = [badge, year].filter(Boolean).join(', ');

  const carModelSkeletonJsx = (
    <>
      <Skeleton width="100%" />
      <Skeleton width="50%" />
    </>
  );

  const carModelJsx = (
    <Typography component="div">
      <Typography className={classes.name}>{vehicleName}</Typography>
      <Typography color="textSecondary">{descriptionSubtitle}</Typography>
    </Typography>
  );

  return (
    <Box display="flex" flexDirection="column" {...rest}>
      {loading ? carModelSkeletonJsx : carModelJsx}
    </Box>
  );
};

CarModel.propTypes = {
  name: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  badge: PropTypes.string,
  year: PropTypes.number,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

CarModel.defaultProps = {
  name: '',
  make: '',
  model: '',
  badge: '',
  year: 0,
  loading: false,
};

export default withStyles(styles)(CarModel);
