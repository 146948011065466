import moment from 'moment';

import { vehicle as vehicleModel } from '../../../models';

import errorService from '../../errorService';
import makeRequest from '../utils/makeRequest';

const baseVehiclesUrl = '/vehicles';

const search = async ({
  showAll,
  flexibleDates,
  from,
  to,
  radius,
  latitude,
  longitude,
  makes,
  minYear,
  maxYear,
  minPrice,
  maxPrice,
}) =>
  makeRequest({
    method: 'get',
    url: '/search',
    params: {
      showAll,
      flexibleDates,
      from,
      to,
      radius,
      latitude,
      longitude,
      makes,
      minYear,
      maxYear,
      minPrice,
      maxPrice,
    },
    errorWrapper: errorService.vehicle.loadingErr,
  }).then((r) => ({ ...r, vehicles: r.vehicles.map(vehicleModel.parse) }));

const getFeatured = async () =>
  makeRequest({
    method: 'get',
    url: `${baseVehiclesUrl}/featured`,
    errorWrapper: errorService.vehicle.loadingErr,
  }).then((r) => r.map(vehicleModel.parse));

const getOccupations = async ({ vehicleId, month, year }) =>
  makeRequest({
    method: 'get',
    url: `${baseVehiclesUrl}/${vehicleId}/occupieddays`,
    params: { month, year },
    errorWrapper: errorService.vehicle.occupiedDaysErr,
  }).then((r) => r.map((d) => ({ ...d, from: moment(d.from), to: moment(d.to) })));

const getVehicle = async (vehicleId, currency) =>
  makeRequest({
    method: 'get',
    url: `${baseVehiclesUrl}/${vehicleId}`,
    params: {
      currency,
    },
    errorWrapper: errorService.vehicle.loadingErr,
  }).then((r) => vehicleModel.parse(r.vehicle));

const getTotals = async ({ vehicleId, from, to, currency }) =>
  makeRequest({
    method: 'get',
    url: `${baseVehiclesUrl}/${vehicleId}/totals`,
    errorWrapper: errorService.vehicle.totalsErr,
    params: {
      from,
      to,
      currency,
    },
  }).then((r) => vehicleModel.parseTotals(r));

const getTotalsExtensions = async ({ vehicleId, from, to, currency }) =>
  makeRequest({
    method: 'get',
    url: `${baseVehiclesUrl}/${vehicleId}/totalsextensions`,
    errorWrapper: errorService.vehicle.totalsErr,
    params: {
      from,
      to,
      currency,
    },
  });

export { search, getFeatured, getOccupations, getVehicle, getTotals, getTotalsExtensions };
