import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const CardSection = ({ children, fullHeight, classes, ...rest }) => {
  const sectionClasses = clsx(classes.container, fullHeight && classes.fullHeight);
  return (
    <Box className={sectionClasses} {...rest}>
      {children}
    </Box>
  );
};

CardSection.propTypes = {
  children: PropTypes.node,
  fullHeight: PropTypes.bool,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    fullHeight: PropTypes.string.isRequired,
  }).isRequired,
};
CardSection.defaultProps = {
  children: null,
  fullHeight: false,
};

export default withStyles(styles)(CardSection);
