import PropTypes from 'prop-types';
import React from 'react';

import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import { LazyContent } from '../../../base';

import Image from '../Image';
import styles from './styles';

const VehiclePhoto = ({ url, lazy, loading, classes }) => {
  const imageJsx = lazy ? (
    <LazyContent className={classes.image}>
      <Image src={url} alt="" className={classes.image} />
    </LazyContent>
  ) : (
    <Image src={url} alt="" className={classes.image} />
  );

  return (
    <>
      {loading ? (
        <Skeleton variant="rect" width="100%" height="100%" style={{ minHeight: '200px' }} />
      ) : (
        imageJsx
      )}
    </>
  );
};

VehiclePhoto.propTypes = {
  url: PropTypes.string,
  lazy: PropTypes.bool,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    image: PropTypes.string.isRequired,
  }).isRequired,
};

VehiclePhoto.defaultProps = {
  url: '',
  lazy: false,
  loading: false,
};

export default withStyles(styles)(VehiclePhoto);
