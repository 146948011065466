import qs from 'qs';
import { takeEvery, put, call, select } from 'redux-saga/effects';

import { setCurrency, setRegion } from 'store/modules/app/actions';
import * as actionTypes from 'store/modules/app/actionTypes';
import { getRegion } from 'store/modules/app/selectors';

import { Region, Currency } from 'evee-ui-common/enums';

function* setAppRegion() {
  let region = Region.australia;

  if (window.location.search) {
    const { region: regionId } =
      qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });

    if (regionId && regionId === Region.newZealand.id) {
      region = Region.newZealand;
    }
  }

  yield put(setRegion(region));
}

function* checkAndSetCurrency() {
  const region = yield select(getRegion);

  if (region && region.id === Region.newZealand.id) {
    yield put(setCurrency(Currency.nzd));
    return;
  }

  yield put(setCurrency(Currency.aud));
}

function* onAppInit() {
  try {
    yield call(setAppRegion);
    yield call(checkAndSetCurrency);
  } catch (e) {
    console.log(e);
  }
}

export default function* listenAppInit() {
  yield takeEvery(actionTypes.APP_INIT, onAppInit);
}
