import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

import { CardActionArea, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { formatPriceNumber } from '../../../utils';
import { vehicle as vehicleTypes } from '../../../types';

import CardSection from '../utils/CardSection';
import FeatureTag from '../utils/FeatureTag';
import ModelAndRating from '../utils/ModelAndRating';
import VehicleCardBase from '../utils/VehicleCardBase';
import VehicleLocation from '../utils/VehicleLocation';
import VehiclePhoto from '../utils/VehiclePhoto';

import TotalPayout from './TotalPayout';
import styles from './styles';

const SearchVehicleCard = ({ vehicle, selected, VehicleLink, loading, classes, onHover }) => {
  const { id, shortName, thumbnail, total, totalBeforeDiscount } = vehicle;
  const totalDiscount = totalBeforeDiscount - total;
  const cardClass = clsx(classes.baseContainer, selected && classes.selectedCard);

  return (
    <VehicleLink id={id} shortName={shortName}>
      <VehicleCardBase classes={{ container: cardClass }}>
        <CardActionArea
          disableRipple
          disableTouchRipple
          className={classes.actionArea}
          classes={{ focusHighlight: classes.cardFocus }}
          onMouseEnter={() => onHover(vehicle)}
          onMouseLeave={() => onHover({})}
        >
          <FeatureTag {...vehicle} />

          <Grid item xs={12} sm={6} md={6} lg={12}>
            <VehiclePhoto
              lazy
              loading={loading}
              url={thumbnail}
              classes={{ image: classes.image }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={12}>
            <CardSection fullHeight className={classes.cardSection}>
              <ModelAndRating vehicle={vehicle} loading={loading} />
              <VehicleLocation shortAddress={vehicle.deliveryAddress.name} loading={loading} />
            </CardSection>

            <CardSection fullHeight className={classes.cardSection}>
              <TotalPayout
                total={formatPriceNumber(total)}
                totalBeforeDiscount={formatPriceNumber(totalBeforeDiscount)}
                totalDiscount={totalDiscount > 0 ? formatPriceNumber(totalDiscount) : ''}
                loading={loading}
              />
            </CardSection>
          </Grid>
        </CardActionArea>
      </VehicleCardBase>
    </VehicleLink>
  );
};

SearchVehicleCard.propTypes = {
  vehicle: vehicleTypes.vehicle,
  selected: PropTypes.bool,
  VehicleLink: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    actionArea: PropTypes.string.isRequired,
    cardSection: PropTypes.string.isRequired,
    carModelName: PropTypes.string.isRequired,
    baseContainer: PropTypes.string.isRequired,
    selectedCard: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    cardFocus: PropTypes.string.isRequired,
  }).isRequired,
  onHover: PropTypes.func,
};

SearchVehicleCard.defaultProps = {
  vehicle: {
    deliveryAddress: {
      name: '',
    },
  },
  selected: false,
  loading: false,
  onHover: () => {},
};

export default withStyles(styles)(SearchVehicleCard);
