import MomentPropTypes from 'react-moment-proptypes';
import PropTypes from 'prop-types';

const stripeAccount = PropTypes.shape({
  birthDate: MomentPropTypes.momentObj,
  isBusiness: PropTypes.bool,
  businessNumber: PropTypes.string,
  businessName: PropTypes.string,
  gstRegistered: PropTypes.bool,
});

const bankAccount = PropTypes.shape({
  id: PropTypes.string,
  bankName: PropTypes.string,
  name: PropTypes.string,
  last4: PropTypes.string,
  isDefault: PropTypes.bool,
});

export default {
  stripeAccount,
  bankAccount,
};
