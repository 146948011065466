import { bookings as bookingsModel } from '../../../models';
import makeRequest from '../utils/makeRequest';

const baseBookingUrl = '/bookings';

export const create = async (basketId, cardId, message, cfclick) =>
  makeRequest({
    method: 'post',
    url: baseBookingUrl,
    body: {
      basketId,
      card: cardId,
      message,
      marketingData: { cfclick },
    },
  });

export const cancel = async (bookingId, cancellationReason) =>
  makeRequest({
    method: 'post',
    url: `${baseBookingUrl}/${bookingId}/cancel`,
    body: {
      reason: cancellationReason,
    },
  });

export const reject = async (bookingId, cancellationReason) =>
  makeRequest({
    method: 'post',
    url: `${baseBookingUrl}/${bookingId}/reject`,
    body: {
      reason: cancellationReason,
    },
  });

export const confirm = async (bookingId) =>
  makeRequest({
    method: 'post',
    url: `${baseBookingUrl}/${bookingId}/confirm`,
  });

export const get = async (bookingId, currency) =>
  makeRequest({
    method: 'get',
    url: `${baseBookingUrl}/${bookingId}`,
    params: { currency },
  }).then((booking) => bookingsModel.parseOne(booking));

export const getMyCars = async (status) =>
  makeRequest({
    method: 'get',
    url: `${baseBookingUrl}/listed`,
    params: { status },
  }).then((answer) => bookingsModel.parse(answer));

export const getMyTrips = async (
  role,
  filterType,
  page = 0,
  size = 4,
  vehicleIds = [],
  sort = [],
) =>
  makeRequest({
    method: 'get',
    url: baseBookingUrl,
    params: { role, filterType, page, size, vehicleIds, sort },
  }).then((answer) => bookingsModel.parseTrips(answer));

export const getStats = async (role, vehicleIds = []) =>
  makeRequest({
    method: 'get',
    url: `${baseBookingUrl}/stats`,
    params: { role, vehicleIds },
  }).then((response) => bookingsModel.parseStats(response));

export const createReview = async (bookingId, review, rating) =>
  makeRequest({
    method: 'post',
    url: `${baseBookingUrl}/${bookingId}/reviews`,
    body: { review, rating },
  });

export const getReviews = async (bookingId) =>
  makeRequest({
    method: 'get',
    url: `${baseBookingUrl}/${bookingId}/reviews`,
  });

export const inviteAdditionalDriver = async (bookingId, email) =>
  makeRequest({
    method: 'post',
    url: `${baseBookingUrl}/${bookingId}/driver`,
    body: {
      email,
    },
  });

export const removeDriver = async (bookingId) =>
  makeRequest({
    method: 'delete',
    url: `${baseBookingUrl}/${bookingId}/driver`,
  });

export const authoriseSecurityDeposit = async (bookingId, card) =>
  makeRequest({
    method: 'put',
    url: `${baseBookingUrl}/${bookingId}/securityDeposit`,
    body: {
      card,
    },
  });

export const addExtra = async (bookingId, currency, extra, card) =>
  makeRequest({
    method: 'put',
    params: { currency },
    url: `${baseBookingUrl}/${bookingId}/extras/${extra.type}`,
    body: {
      card,
    },
  });

export const removeExtra = async (bookingId, currency, extra) =>
  makeRequest({
    method: 'delete',
    params: { currency },
    url: `${baseBookingUrl}/${bookingId}/extras/${extra.type}`,
  });
