const styles = (theme) => ({
  carIcon: {
    width: '60px',
    height: '24px',
    marginBottom: theme.spacing(4),
  },
  listCar: {
    height: '100%',
    minHeight: '330px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(1),
  },
});

export default styles;
