import { lighten } from '@material-ui/core/styles';

const styles = (theme) => ({
  discount: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: lighten(theme.palette.success.light, 0.84),
    color: theme.palette.success.dark,
    padding: '4px 6px',
    borderRadius: '3px',
  },
  discountTypography: {
    fontSize: '10px',
    fontWeight: 500,
  },
});

export default styles;
