import PropTypes from 'prop-types';
import React from 'react';

import { CardActionArea } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { vehicle as vehicleTypes } from '../../../types';

import CardSection from '../utils/CardSection';
import ModelAndRating from '../utils/ModelAndRating';
import VehicleCardBase from '../utils/VehicleCardBase';
import VehicleLink from '../utils/VehicleLink';
import VehiclePhoto from '../utils/VehiclePhoto';
import styles from './styles';

const CommonVehicleCard = ({ vehicle, hideRating, classes }) => {
  const { id, shortName, thumbnail } = vehicle;

  return (
    <VehicleLink id={id} shortName={shortName}>
      <VehicleCardBase classes={{ container: classes.cardContainer }}>
        <CardActionArea className={classes.actionArea}>
          <VehiclePhoto url={thumbnail} />
          <CardSection fullHeight>
            <ModelAndRating
              vehicle={vehicle}
              hideRating={hideRating}
              classes={{ carModel: classes.carModelName }}
            />
          </CardSection>
        </CardActionArea>
      </VehicleCardBase>
    </VehicleLink>
  );
};

CommonVehicleCard.propTypes = {
  vehicle: vehicleTypes.vehicle.isRequired,
  hideRating: PropTypes.bool,
  classes: PropTypes.shape({
    cardContainer: PropTypes.string,
    footerContainer: PropTypes.string.isRequired,
    actionArea: PropTypes.string.isRequired,
    carModelName: PropTypes.string,
  }).isRequired,
};

CommonVehicleCard.defaultProps = {
  hideRating: false,
};

export default withStyles(styles)(CommonVehicleCard);
