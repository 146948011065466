import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { CardActionArea } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '../../base';
import { vehicle as vehicleTypes } from '../../../types';

import CardSection from '../utils/CardSection';
import ModelAndRating from '../utils/ModelAndRating';
import VehicleCardBase from '../utils/VehicleCardBase';
import VehicleLink from '../utils/VehicleLink';
import VehiclePhoto from '../utils/VehiclePhoto';
import styles from './styles';

const ListingVehicleCard = ({ vehicle, fullHeight, classes }) => {
  const { id, shortName, thumbnail } = vehicle;

  return (
    <VehicleCardBase style={{ height: fullHeight ? '100%' : 'auto' }}>
      <VehicleLink id={id} shortName={shortName} style={{ height: '100%' }}>
        <CardActionArea className={classes.actionArea}>
          <VehiclePhoto url={thumbnail} />
          <CardSection fullHeight marginBottom="24px">
            <ModelAndRating vehicle={vehicle} />
          </CardSection>
        </CardActionArea>
      </VehicleLink>
      <Button
        text="Edit listing"
        type="text"
        color="primary"
        component={NavLink}
        to={`/listing/${id}`}
        size="large"
        className={classes.btnEdtListings}
      />
    </VehicleCardBase>
  );
};

ListingVehicleCard.propTypes = {
  vehicle: vehicleTypes.vehicle.isRequired,
  fullHeight: PropTypes.bool,
  classes: PropTypes.shape({
    bodyContainer: PropTypes.string,
    footerContainer: PropTypes.string,
    rating: PropTypes.string,
    reviewsPlaceholder: PropTypes.string,
    actionArea: PropTypes.string,
    carModelName: PropTypes.string,
    btnEdtListings: PropTypes.string,
  }).isRequired,
};

ListingVehicleCard.defaultProps = {
  fullHeight: false,
};

export default withStyles(styles)(ListingVehicleCard);
