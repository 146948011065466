import { ExtraStatus } from '../enums';

interface DayPrice {
  date: string;
  discountAmount: number;
  fee: number;
  payout: number;
  price: number;
  priceBeforeDiscount: number;
}

interface Extra {
  dailyRate: number;
  error: string;
  status: string;
  total: number;
  type: string;
}

interface ExtraDiff {
  diff: number;
  type: string;
}

interface ChangeRequest {
  _id: string;
  accepter: string;
  booking: string;
  changes: {
    bookingDaysPrices: [DayPrice];
    days: number;
    extras: [Extra];
    extrasDiffs: [ExtraDiff];
    extrasDiffTotal: number;
    fee: number;
    from: string;
    payout: number;
    payoutDiffs: number;
    to: string;
    total: number;
    totalDiff: number;
  };
  chargeId: string;
  createdBy: string;
  createdByName: string;
  createdDate: string;
  modifiedBy: string;
  modifiedByName: string;
  modifiedDate: string;
  requester: string;
  status: string;
}

interface ChangeRequestTotals {
  bookingDaysPrices: [DayPrice];
  currency: string;
  days: number;
  deliveryPrice: number;
  discountPercent: number;
  extras: [Extra];
  fee: number;
  payout: number;
  total: number;
  totalBeforeDiscount: number;
}

const parse = (requestRaw: ChangeRequest) =>
  requestRaw
    ? {
        ...requestRaw,
        id: requestRaw._id,
        changes: {
          ...requestRaw.changes,
          extrasDiffsTotal:
            requestRaw?.changes?.extrasDiffs?.reduce((acc, e) => acc + e.diff, 0) || 0,
        },
      }
    : null;

const parseTotals = (requestRaw: ChangeRequestTotals) =>
  requestRaw
    ? {
        ...requestRaw,
        extrasTotal:
          requestRaw?.extras
            ?.filter((e) => e.status === ExtraStatus.applied || e.status === ExtraStatus.draft)
            ?.reduce((acc, e) => acc + e.total, 0) || 0,
      }
    : null;

export default {
  parse,
  parseTotals,
};
