import TagManager from 'react-gtm-module';
import _ from 'lodash';
import moment from 'moment';

import { DEFAULT_LOCATION_NAME, SortDirection } from '../../enums';
import { capitalizeFirstLetter } from '../../utils';

const transactionTax = (total) => total / 11; // GST 10% included in total

const getCategory = (vehicle) => {
  const category = [
    vehicle?.deliveryAddress?.state,
    vehicle.make,
    vehicle.model,
    vehicle.badge,
    vehicle.year,
  ];
  return _.compact(category).join('/');
};

function getProducts(booking, vehicle) {
  const result = [
    {
      id: booking.vehicle.id,
      sku: booking.vehicle.id,
      category: getCategory(vehicle),
      name: booking.vehicle.name,
      price:
        booking.days > 1
          ? Number((booking.total / booking.days).toFixed(0))
          : Number(booking.total),
      quantity: booking.days,
      brand: booking.owner.fullName,
    },
  ];

  if (booking.delivery.price > 0) {
    result.push({
      id: undefined, // todo: set correct value
      sku: undefined, // todo: set correct value
      category: booking.delivery.type ? capitalizeFirstLetter(booking.delivery.type) : 'Delivery',
      name: booking.delivery.name,
      price: booking.delivery.price,
      quantity: 1,
    });
  }

  if (booking.discountPercent > 0) {
    result.push({
      name: `${booking.days} days discount`,
      category: 'Discounts',
      price: -booking.bookingDaysPrices.map((e) => e.discountAmount).reduce((a, b) => a + b, 0),
      quantity: 1,
    });
  }

  return result;
}

function pushDetail(vehicle) {
  TagManager.dataLayer({
    dataLayer: {
      ecommerce: null,
    },
  });

  TagManager.dataLayer({
    dataLayer: {
      event: 'detail',
      ecommerce: {
        detail: {
          products: [
            {
              id: vehicle.id,
              name: vehicle.name,
              price: vehicle.price,
              brand: vehicle.owner.fullName,
              category: getCategory(vehicle),
              quantity: vehicle.quantity,
            },
          ],
        },
      },
    },
  });
}

function pushCheckout(stepNumber, stepName, basket) {
  TagManager.dataLayer({
    dataLayer: {
      ecommerce: null,
    },
  });

  let products = [];
  products = basket && [
    {
      id: basket.vehicle.id,
      name: basket.vehicle.name,
      thumbnail: basket.vehicle.thumbnail,
      badge: basket.vehicle.badge,
      quantity: basket.days,
    },
  ];

  const getCheckout = () => {
    if (products) {
      return {
        actionField: { step: stepNumber, option: stepName },
        products,
      };
    }

    return {
      actionField: { step: stepNumber, option: stepName },
    };
  };

  TagManager.dataLayer({
    dataLayer: {
      event: 'checkout',
      ecommerce: {
        checkout: getCheckout(),
      },
    },
  });
}

function pushPurchase(booking, vehicle) {
  TagManager.dataLayer({
    dataLayer: {
      ecommerce: null,
    },
  });

  TagManager.dataLayer({
    dataLayer: {
      event: 'purchase',
      ecommerce: {
        purchase: {
          currencyCode: booking.currency,
          actionField: {
            id: booking.id,
            revenue: booking.total,
            tax: transactionTax(booking.total),
            coupon: booking.appliedDiscountCode,
          },
          products: getProducts(booking, vehicle),
        },
      },
    },
  });
}

function pushTransaction(booking, vehicle) {
  TagManager.dataLayer({
    dataLayer: {
      event: 'transaction',
      transactionId: booking.id,
      transactionTotal: booking.total,
      transactionTax: transactionTax(booking.total),
      transactionProducts: getProducts(booking, vehicle),
    },
  });
}

function pushImpressions(vehicles, listName) {
  if (!vehicles.length) {
    return;
  }

  TagManager.dataLayer({
    dataLayer: {
      ecommerce: null,
    },
  });

  const impressions = vehicles.map((v, index) => ({
    name: v.name,
    id: v.id,
    price: v.pricePerDay,
    brand: v.owner.fullName,
    category: getCategory(v),
    list: listName,
    position: index + 1,
  }));

  TagManager.dataLayer({
    dataLayer: {
      event: 'impressions',
      ecommerce: {
        currencyCode: 'AUD',
        impressions,
      },
    },
  });
}

function pushSearchResultsImpressions(vehicles) {
  pushImpressions(vehicles, 'Search Results List');
}

function pushSelectedSearchResult(vehicle) {
  if (_.isEmpty(vehicle)) {
    return;
  }

  pushImpressions([vehicle], 'Search Results Map');
}

function pushFeaturedVehicles(vehicles) {
  pushImpressions(vehicles, 'Homepage Featured Vehicles');
}

function pushSearchRequest(query) {
  let sortMode = 'Popular';
  if (query.sort.length > 0) {
    const sortByField = query.sort[0];
    if (sortByField.direction === SortDirection.ascending) {
      sortMode = 'Low to high';
    } else {
      sortMode = 'High to low';
    }
  }

  TagManager.dataLayer({
    dataLayer: {
      event: 'search',
      searchLocationName: query.locationName === DEFAULT_LOCATION_NAME ? '' : query.locationName,
      searchLocationLat: query.latitude,
      searchLocationLng: query.longitude,
      searchFrom: moment(query.from).format('lll'),
      searchTo: moment(query.to).format('lll'),
      searchFromDate: query.from,
      searchToDate: query.to,
      searchFlexibleDates: query.flexibleDates,
      filterMake: query.makes.length ? query.makes.map((e) => e.name).join(',') : undefined,
      filterModel: query.models.length ? query.models.map((e) => e.name).join(',') : undefined,
      filterBadge: query.badges.length ? query.badges.map((e) => e.name).join(',') : undefined,
      filterYearMin: query.minYear,
      filterYearMax: query.maxYear,
      filterPriceMin: query.minPrice,
      filterPriceMax: query.maxPrice,
      sortBy: sortMode,
    },
  });
}

function pushSignIn(data) {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sign in',
      email: data.email,
      method: data.method,
    },
  });
}

function pushSignUp(data) {
  TagManager.dataLayer({
    dataLayer: {
      event: 'sign up',
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      method: data.method,
    },
  });
}

function pushBookingEvent(action, label, event = 'Booking') {
  TagManager.dataLayer({
    dataLayer: {
      event,
      action,
      label,
    },
  });
}

function pushSpecialOffer(code, event = 'Special offer') {
  TagManager.dataLayer({
    dataLayer: {
      event,
      code,
    },
  });
}

export default {
  pushDetail,
  pushCheckout,
  pushPurchase,
  pushTransaction,
  pushSearchResultsImpressions,
  pushSelectedSearchResult,
  pushFeaturedVehicles,
  pushSearchRequest,
  pushSignIn,
  pushSignUp,
  pushBookingEvent,
  pushSpecialOffer,
};
