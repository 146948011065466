import { Region, Currency } from 'evee-ui-common/enums';

export default {
  region: Region.australia,
  currency: Currency.aud,
  toast: {
    open: false,
    message: '',
    type: '',
  },
};
