import { decrypt, encrypt } from '../../../utils';

export const save = ({ email, password }) => {
  localStorage.setItem(
    'creds',
    JSON.stringify({
      email: encrypt(email),
      password: encrypt(password),
    }),
  );
};

export const get = () => {
  const credsString = localStorage.getItem('creds');
  if (!credsString) {
    return null;
  }

  const { email, password } = JSON.parse(credsString);
  return {
    email: decrypt(email),
    password: decrypt(password),
  };
};

export const remove = () => {
  localStorage.removeItem('creds');
};
