import { lighten } from '@material-ui/core';
import { red, green, blue, orange, grey } from '@material-ui/core/colors';
import { alpha, createTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const navyBlue = '#4366b4';

export const ochre = '#ffae6e';
export const darkOchre = '#C97E41';
export const lightOchre = '#fbede2';
export const midnightBlue = '#1e2832';
export const skyBlue = '#bed7eb';
export const stone = '#F4F0EB';

const lightGrey = grey[100];

const theme = createTheme({
  palette: {
    common: {
      red,
      green,
      orange,
      navyBlue,
      blue,
      midnightBlue,
      skyBlue,
      stone,
    },
    primary: {
      main: midnightBlue,
      contrastText: midnightBlue,
    },
    secondary: {
      main: ochre,
      dark: darkOchre,
      contrastText: midnightBlue,
    },
    background: {
      main: lightGrey,
      secondary: lightOchre,
    },
    border: {
      main: '#d2d4d6',
      active: midnightBlue,
    },
    text: {
      primary: midnightBlue,
      secondary: alpha(midnightBlue, 0.7),
    },
    error: {
      main: red[500],
    },
    warning: {
      main: orange[800],
    },
    success: {
      main: green[500],
    },
    divider: '#d2d4d6',
  },
  shape: {
    borderRadiusSmall: 4,
    borderRadius: 8,
    borderRadiusMedium: 16,
    borderRadiusLarge: 32,
  },
  typography: {
    fontFamily: 'FK Grotesk Neue Regular, FKGroteskNeue, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontSize: '4rem',
      fontWeight: '500',
      color: midnightBlue,
      lineHeight: 1.44,
    },
    h2: {
      fontSize: '3rem',
      fontWeight: '500',
      color: midnightBlue,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: '500',
      color: midnightBlue,
      lineHeight: 1.25,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: '500',
      color: midnightBlue,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: '500',
      color: midnightBlue,
      lineHeight: 1.25,
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: '500',
      color: midnightBlue,
    },
    subtitle1: {
      fontSize: '1rem',
      color: midnightBlue,
    },
    body1: {
      color: midnightBlue,
      fontSize: '1rem',
      lineHeight: '24px',
    },
    body2: {
      color: midnightBlue,
      fontSize: '0.875rem',
      lineHeight: '18px',
    },
    caption: {
      color: lighten(midnightBlue, 0.2),
      fontSize: '0.75rem',
      lineHeight: 1.2,
    },
  },
  overrides: {
    // MuiCssBaseline: {
    //   '@global': {
    //     '@font-face': fonts.fKGroteskNeueRemote,
    //   },
    // },
    MuiScopedCssBaseline: {
      root: {
        backgroundColor: 'white',
        fontFamily: 'FK Grotesk Neue Regular, FKGroteskNeue',
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
