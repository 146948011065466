import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

const datePropType = PropTypes.oneOfType([
  PropTypes.string,
  momentPropTypes.momentObj,
  PropTypes.instanceOf(Date),
]);

const dateRangePropType = PropTypes.arrayOf(
  PropTypes.shape({
    from: datePropType,
    to: datePropType,
    isBooking: PropTypes.bool,
  }),
);

const priceRange = PropTypes.shape({
  id: PropTypes.string,
  from: datePropType,
  to: datePropType,
  price: PropTypes.number,
});

export default {
  datePropType,
  dateRangePropType,
  priceRange,
};
