import PropTypes from 'prop-types';

import DatePropTypes from './date';

const bookingDayPrice = PropTypes.shape({
  date: PropTypes.string,
  priceBeforeDiscount: PropTypes.number,
  discountAmount: PropTypes.number,
  price: PropTypes.number,
  payout: PropTypes.number,
  fee: PropTypes.number,
});

const totals = PropTypes.shape({
  vehicle: PropTypes.string,
  days: PropTypes.number,
  totalRental: PropTypes.number,
  deliveryPrice: PropTypes.number,
  total: PropTypes.number,
  payout: PropTypes.number,
  fee: PropTypes.number,
  bookingDaysPrices: PropTypes.arrayOf(bookingDayPrice),
  discountPercent: PropTypes.number,
  from: PropTypes.string,
  to: PropTypes.string,
});

const bookingTotals = PropTypes.shape({
  total: PropTypes.number,
  totalRental: PropTypes.number,
  discountPercent: PropTypes.number,
  days: PropTypes.number,
});

const location = PropTypes.shape({
  id: PropTypes.string,
  country: PropTypes.string,
  default: PropTypes.bool,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  name: PropTypes.string,
  postcode: PropTypes.string,
  price: PropTypes.number,
  state: PropTypes.string,
  street: PropTypes.string,
  suburb: PropTypes.string,
  type: PropTypes.string,
  utcOffset: PropTypes.number,
});

const tripDates = PropTypes.shape({
  start: DatePropTypes.datePropType,
  end: DatePropTypes.datePropType,
  available: PropTypes.bool,
  errorMessage: PropTypes.string,
});

const summary = PropTypes.shape({
  name: PropTypes.string,
  badge: PropTypes.string,
  year: PropTypes.number,
  tripsCount: PropTypes.number,
  reviewsCount: PropTypes.number,
  averageRating: PropTypes.string,
  price: PropTypes.number,
});

const owner = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  photo: PropTypes.string,
  nickname: PropTypes.string,
  averageRating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  reviewsCount: PropTypes.number,
});

const photo = PropTypes.shape({
  key: PropTypes.string,
  order: PropTypes.number,
  url: PropTypes.string,
});

const vehicle = PropTypes.shape({
  id: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  averageRating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  photos: PropTypes.arrayOf(photo),
  owner,
  features: PropTypes.array,
  description: PropTypes.string,
  reviews: PropTypes.array,
});

const review = PropTypes.shape({
  customer: PropTypes.string,
  name: PropTypes.string,
  photo: PropTypes.string,
  nickname: PropTypes.string,
  review: PropTypes.string,
  rating: PropTypes.number,
  date: PropTypes.string,
});

const chargingPlug = PropTypes.shape({
  name: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
});

const feature = PropTypes.shape({
  icon: PropTypes.string,
  name: PropTypes.string,
});

const charging = PropTypes.shape({
  freeFastCharging: PropTypes.bool,
  fastChargingProviders: PropTypes.arrayOf(PropTypes.string),
  realDrivingRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rapidPlugs: PropTypes.arrayOf(chargingPlug),
});

export default {
  totals,
  bookingTotals,
  location,
  tripDates,
  summary,
  owner,
  photo,
  vehicle,
  review,
  chargingPlug,
  feature,
  charging,
};
