import PropTypes from 'prop-types';
import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const VehicleLocation = ({ shortAddress, loading, classes }) => (
  <Box display="flex" alignItems="center" justifyContent="flex-start">
    {loading ? (
      <Skeleton width="50%" height="2em" />
    ) : (
      <Typography color="textSecondary">{shortAddress}</Typography>
    )}
  </Box>
);

VehicleLocation.propTypes = {
  shortAddress: PropTypes.string,
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    address: PropTypes.string.isRequired,
  }).isRequired,
};

VehicleLocation.defaultProps = {
  shortAddress: '',
  loading: false,
};

export default withStyles(styles)(VehicleLocation);
