const styles = (theme) => ({
  baseContainer: {
    borderWidth: 0,
    borderRadius: 0,
    height: '100%',
  },
  actionArea: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyItems: 'flex-start',
    [theme.breakpoints.between('sm', 'md')]: {
      flexDirection: 'row',
    },
  },
  cardSection: {
    padding: 0,
    margin: '16px 0',
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '0 16px',
    },
  },
  carModelName: {
    ...theme.typography.body2,
    marginTop: theme.spacing(1),
  },
  selectedCard: {
    boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      background: 'none',
    },
  },
  image: {
    minHeight: '190px',
    borderRadius: '8px',
    height: '100%',
  },
  cardFocus: {
    backgroundColor: 'transparent',
  },
});

export default styles;
