import { stringCompare } from '../../../utils';

import countryCallingCodes from './utils/countryCallingCodes';
import makeRequest from '../utils/makeRequest';

const vehicleModelsBase = 'vehicleModels';

const getVehicleModels = async () =>
  makeRequest({
    method: 'get',
    url: vehicleModelsBase,
  });

const getFeaturedVehicleModels = async (currency, take = 15) =>
  makeRequest({
    method: 'get',
    params: {
      take,
      currency,
    },
    url: `${vehicleModelsBase}/featured`,
  });

const getVehicleModelsPricing = async (currency) =>
  makeRequest({
    method: 'get',
    params: {
      currency,
    },
    url: `${vehicleModelsBase}/pricing`,
  });

const getFeatures = () => [
  // Driving

  {
    name: 'Range',
    icon: 'vehicle-range',
    category: 'Driving',
    important: true,
    categoryOrder: 1,
    itemOrder: 1,
  },
  {
    name: 'App access',
    icon: 'app-access',
    category: 'Driving',
    important: true,
    categoryOrder: 1,
    itemOrder: 2,
  },
  {
    name: 'All-wheel drive',
    icon: 'all-wheel-drive',
    category: 'Driving',
    categoryOrder: 1,
    itemOrder: 2,
  },
  { name: 'GPS', icon: 'gps', category: 'Driving', categoryOrder: 1, itemOrder: 2 },
  {
    name: 'Driver assistance',
    icon: 'autopilot',
    category: 'Driving',
    categoryOrder: 1,
    itemOrder: 2,
  },
  {
    name: 'Park assist',
    icon: 'park-assist',
    category: 'Driving',
    important: true,
    categoryOrder: 1,
    itemOrder: 2,
  },
  {
    name: 'Air suspension',
    icon: 'air-suspension',
    category: 'Driving',
    categoryOrder: 1,
    itemOrder: 2,
  },
  {
    name: 'Tesla FSD',
    icon: 'full-self-driving',
    category: 'Driving',
    important: true,
    categoryOrder: 1,
    itemOrder: 2,
  },

  // Charging

  {
    name: 'Charging adapters',
    icon: 'charging-adapters',
    category: 'Charging',
    categoryOrder: 2,
    itemOrder: 1,
  },
  {
    name: 'Fast charge',
    icon: 'fast-charge',
    category: 'Charging',
    categoryOrder: 2,
    itemOrder: 1,
  },
  {
    name: 'Free fast charging',
    icon: 'free-charging',
    category: 'Charging',
    important: true,
    categoryOrder: 2,
    itemOrder: 1,
  },

  // Comfort

  {
    name: 'Doors',
    icon: 'doors',
    category: 'Comfort',
    important: true,
    categoryOrder: 3,
    itemOrder: 1,
  },
  {
    name: 'Seats',
    icon: 'seats',
    category: 'Comfort',
    important: true,
    categoryOrder: 3,
    itemOrder: 1,
  },
  { name: 'Audio input', icon: 'audio-input', category: 'Comfort', categoryOrder: 3, itemOrder: 2 },
  { name: 'USB input', icon: 'usb-input', category: 'Comfort', categoryOrder: 3, itemOrder: 2 },
  { name: 'Bluetooth', icon: 'bluetooth', category: 'Comfort', categoryOrder: 3, itemOrder: 2 },
  { name: 'Hi-Fi sound', icon: 'hi-fi-sound', category: 'Comfort', categoryOrder: 3, itemOrder: 2 },
  {
    name: 'Child seat',
    icon: 'child-seat',
    category: 'Comfort',
    important: true,
    categoryOrder: 3,
    itemOrder: 2,
  },
  {
    name: 'Ventilated seats',
    icon: 'ventilated-seats',
    category: 'Comfort',
    categoryOrder: 3,
    itemOrder: 2,
  },
  {
    name: 'Premium interior',
    icon: 'premium-interior',
    category: 'Comfort',
    categoryOrder: 3,
    itemOrder: 2,
  },
  {
    name: 'Android auto',
    icon: 'android-auto',
    category: 'Comfort',
    categoryOrder: 3,
    itemOrder: 2,
  },
  {
    name: 'Carplay',
    icon: 'carplay',
    category: 'Comfort',
    important: true,
    categoryOrder: 3,
    itemOrder: 2,
  },
  {
    name: 'Heated seats',
    icon: 'heated-seats',
    category: 'Comfort',
    categoryOrder: 3,
    itemOrder: 2,
  },
  { name: 'Sunroof', icon: 'sunroof', category: 'Comfort', categoryOrder: 3, itemOrder: 2 },

  // Renting

  { name: 'ride-length', icon: 'ride-length', category: 'Renting', categoryOrder: 4, itemOrder: 1 },
  {
    name: 'Vehicle delivery',
    icon: 'vehicle-delivery',
    category: 'Renting',
    categoryOrder: 4,
    itemOrder: 2,
  },
  {
    name: 'Pet friendly',
    icon: 'pet-friendly',
    category: 'Renting',
    categoryOrder: 4,
    itemOrder: 2,
  },

  // Extras

  { name: 'Bike rack', icon: 'bike-rack', category: 'Extras', categoryOrder: 5, itemOrder: 1 },
  { name: 'Glass roof', icon: 'glass-roof', category: 'Extras', categoryOrder: 5, itemOrder: 1 },
  { name: 'Ski rack', icon: 'ski-rack', category: 'Extras', categoryOrder: 5, itemOrder: 1 },
  { name: 'Roof rack', icon: 'roof-rack', category: 'Extras', categoryOrder: 5, itemOrder: 1 },
  { name: 'Tow bar', icon: 'tow-bar', category: 'Extras', categoryOrder: 5, itemOrder: 1 },
];

const getChargingPlugs = () => [
  { name: 'Tesla supercharger', icon: 'tesla_supercharger', type: 'rapid' },
  { name: 'CCS', icon: 'ccs_sae', type: 'rapid' },
  { name: 'CHAdeMO', icon: 'chademo', type: 'rapid' },
  { name: 'Tesla supercharger', icon: 'tesla_supercharger_ccs', type: 'rapid' },
  { name: 'J-1772', icon: 'j1772', type: 'standard' },
  { name: 'Type 2', icon: 'type2', type: 'standard' },
  { name: 'Tesla destination', icon: 'tesla_destination', type: 'standard' },
  { name: 'Three phase', icon: 'three_phase', type: 'standard' },
  { name: 'Wall', icon: 'wall', type: 'standard' },
];

const getDriverQuestionnaire = () => [
  {
    id: 1,
    question:
      'Had any motor insurance and/or claims refused and/or cancelled and/or imposed special terms in the last three (3) years?',
    validAnswer: 'no',
  },
  {
    id: 2,
    question:
      'Had any convictions for driving under the influence of alcohol or drugs (DUI) and/or exceeding the prescribed content of alcohol (PCA) in the last three (3) years?',
    validAnswer: 'no',
  },
  {
    id: 3,
    question: 'Had a licence suspended or cancelled in the last three (3) years?',
    validAnswer: 'no',
  },
  {
    id: 4,
    question: 'Have currently accumulated more than 9 demerit points on their licence?',
    validAnswer: 'no',
  },
];

const getCountryCallingCodes = () =>
  countryCallingCodes
    .map((e) => ({ ...e, phone: `+${e.phone}` }))
    .sort((a, b) => stringCompare(a.label, b.label));

export {
  getVehicleModels,
  getFeaturedVehicleModels,
  getVehicleModelsPricing,
  getFeatures,
  getChargingPlugs,
  getDriverQuestionnaire,
  getCountryCallingCodes,
};
