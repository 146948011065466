import PropTypes from 'prop-types';

const stepState = PropTypes.shape({
  active: PropTypes.bool,
  completed: PropTypes.bool,
  disabled: PropTypes.bool,
});

const questionnaireItem = PropTypes.shape({
  id: PropTypes.number,
  question: PropTypes.string,
  answer: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  validAnswer: PropTypes.string,
});

export default {
  stepState,
  questionnaireItem,
};
