import { eventChannel } from 'redux-saga';
import { io } from 'socket.io-client';

import { getCustomerId } from '../../utils';

import storage from '../storage';

let ioSocket;

const licenceVerificationEvents = {
  CUSTOMER_VERIFICATION_SUBMITTED: 'customer_verification_submitted',
  CUSTOMER_VERIFICATION_SUCCESS: 'customer_verification_success',
  CUSTOMER_VERIFICATION_FAILED: 'customer_verification_failed',
};

const cardVerificationEvents = {
  CARD_VERIFICATION_UPDATED: 'card_verification_updated',
  CARD_VERIFICATION_SUCCESS: 'card_verification_success',
  CARD_VERIFICATION_FAILED: 'card_verification_failed',
};

const chatEvents = {
  CHAT_MESSAGE_SENT: 'chat_message_sent',
  CHAT_MESSAGE_DELETED: 'chat_message_deleted',
};

const SET_CUSTOMER_ID = 'set_customer_id';
const REMOVE_CUSTOMER_ID = 'remove_customer_id';

const sendCustomerId = (id) => {
  ioSocket.emit(SET_CUSTOMER_ID, id);
};

const removeCustomerId = (id) => {
  ioSocket.emit(REMOVE_CUSTOMER_ID, id);
};

const createSocketChannel = (name) => {
  ioSocket.removeAllListeners(name);
  return eventChannel((emit) => {
    const handler = (data) => {
      emit(data);
    };
    ioSocket.on(name, handler);
    return () => {};
  });
};

const init = () => {
  if (ioSocket) {
    return;
  }

  ioSocket = io(process.env.REACT_APP_SOCKET_URL);

  ioSocket.on('connect', () => {
    const currentUser = storage.user.get();
    if (!currentUser) {
      return;
    }

    sendCustomerId(getCustomerId(currentUser));
  });
};

export default {
  sendCustomerId,
  removeCustomerId,
  createSocketChannel,
  licenceVerificationEvents,
  cardVerificationEvents,
  chatEvents,
  init,
};
