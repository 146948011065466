import _, { pick } from 'lodash';
import humanizeDuration from 'humanize-duration';
import moment from 'moment';

import { BookingStatus, ExtraStatus, LocationType } from '../enums';
import { formatDate } from './common';
import { getCustomerId } from './customerUtils';

export const FULL_REFUND_BEFORE_HOURS = 72;
export const NO_REFUND_BEFORE_HOURS = 48;
export const RESCHEDULE_CHARGE_BEFORE_HOURS = 48;

export const isBookingOwner = (userId, booking) =>
  userId === (booking.owner ? booking.owner.id : '');

const BOOKING_CANCELLED_STATUSES = [
  BookingStatus.cancelled,
  BookingStatus.rejected,
  BookingStatus.expired,
];

export const isBookingCancelled = (status) => BOOKING_CANCELLED_STATUSES.includes(status);

const BOOKING_COMPLETED_STATUSES = [BookingStatus.completing, BookingStatus.completed];

export const isBookingCompleted = (status) => BOOKING_COMPLETED_STATUSES.includes(status);

export const isReadyToReview = (booking) =>
  booking.id && isBookingCompleted(booking.status) && !booking.reviewSubmitted;

export const getMessageData = (booking) => {
  if (booking.messages.length) {
    return booking.messages[booking.messages.length - 1];
  }
  return { message: null, date: null };
};

const getCommonPricingData = (booking) => ({
  deliveryPrice: booking.deliveryPrice,
  days: booking.days,
  bookingDaysPrices: booking.bookingDaysPrices,
});

export const getExtrasTotal = (booking) => {
  return (
    booking?.extras
      ?.filter((e) => e.status === ExtraStatus.applied || e.status === ExtraStatus.draft)
      ?.reduce((acc, e) => acc + e.total, 0) || 0
  );
};

export const getOwnerPricingData = (booking) => {
  const pricing = Object.assign(
    getCommonPricingData(booking),
    pick(booking, ['totalPayoutBeforeFee', 'totalFee', 'payoutPercent']),
  );

  return {
    ...pricing,
    extras: getExtrasTotal(booking),
  };
};

export const getRenterPricingData = (booking) => {
  const pricing = Object.assign(
    getCommonPricingData(booking),
    pick(booking, ['totalRental', 'totalDiscount']),
  );

  return {
    ...pricing,
    extras: getExtrasTotal(booking),
  };
};

export const getBookingAddress = (delivery) => {
  if (!delivery) return '';

  if (delivery.type === LocationType.airport) {
    return delivery.name;
  }

  const addressParts = [delivery.street, delivery.suburb, delivery.postcode, delivery.country];
  return _.compact(addressParts).join(' ');
};

export const getBookingDates = (from, to, formatString = 'D MMM, h A') => {
  const fromYear = moment(from).format('YYYY');
  const toYear = moment(to).format('YYYY');
  return {
    from: formatDate(from, formatString),
    to: formatDate(to, formatString),
    year: fromYear === toYear ? fromYear : `${fromYear} - ${toYear}`,
  };
};

export const getRenterBookingDates = (from, to) => getBookingDates(from, to, 'dd, D MMM, h A');

export const getBookingDatesShort = (from, to) => ({
  from: formatDate(from, 'DD MMM YYYY'),
  to: formatDate(to, 'DD MMM YYYY'),
});

export const getOppositePersonData = (userId, booking) =>
  userId === booking.owner.id ? booking.renter : booking.owner;

export const getDaysStr = (days) => (days > 1 ? 'days' : 'day');

export const canShowRenterLicence = (booking) => {
  const isBetween = moment().isBetween(
    moment(booking.from).add(-24, 'hours'),
    moment(booking.from).add(48, 'hours'),
  );
  return isBetween && booking.status === BookingStatus.confirmed;
};

export const bookingRequestExpires = (booking) => {
  const diff = moment(booking.from).diff(moment(), 'hours');
  return diff <= 4 && diff >= 0;
};

export const bookingRequestExpiresSoon = (booking) => moment(booking.from).diff(moment()) <= 0;

export const fullRefundBefore = (bookingStart) =>
  bookingStart && moment(bookingStart).subtract(FULL_REFUND_BEFORE_HOURS, 'hours');

export const noRefundAfter = (bookingStart) =>
  bookingStart && moment(bookingStart).subtract(NO_REFUND_BEFORE_HOURS, 'hours');

export const rescheduleChargeAfter = (bookingStart) =>
  bookingStart && moment(bookingStart).subtract(RESCHEDULE_CHARGE_BEFORE_HOURS, 'hours');

export const getStatusText = (booking, currentUser) => {
  const isOwner = isBookingOwner(getCustomerId(currentUser), booking);
  const currentMoment = moment();
  const fromMoment = moment(booking.from);
  const dateFormatStr = 'DD.MM.YYYY';

  if (booking.status === BookingStatus.confirmed) {
    const toMoment = moment(booking.to);
    const daysLeft = fromMoment.diff(currentMoment, 'days');
    const isOngoing = currentMoment.isBetween(fromMoment, toMoment);

    if (isOngoing) {
      return 'Ongoing';
    }

    if (currentMoment.format(dateFormatStr) === fromMoment.format(dateFormatStr)) {
      return 'Today';
    }

    if (
      moment(currentMoment).add(1, 'day').format(dateFormatStr) === fromMoment.format(dateFormatStr)
    ) {
      return 'Tomorrow';
    }

    if (daysLeft > 0 && daysLeft <= 7) {
      return `In ${daysLeft} days`;
    }

    if (daysLeft <= 0) {
      return 'Completed';
    }

    const diff = fromMoment.diff(currentMoment);
    if (diff > 0) {
      return `In ${humanizeDuration(diff, { largest: 1 })}`;
    }
  }

  if (booking.status === BookingStatus.requested) {
    const diff = fromMoment.diff(currentMoment);

    if (diff <= 0) {
      return 'Expires soon';
    }

    return isOwner ? `In ${humanizeDuration(diff, { largest: 1 })}` : 'Requested';
  }

  const statusMap = {
    [BookingStatus.requested]: 'Requested',
    [BookingStatus.confirmed]: 'Confirmed',
    [BookingStatus.completed]: 'Completed',
    [BookingStatus.rejected]: 'Cancelled',
    [BookingStatus.cancelled]: 'Cancelled',
    [BookingStatus.expired]: 'Cancelled',
  };
  return statusMap[booking.status];
};

export const getStatusColor = (status, currentUser, booking, theme) => {
  const isOwner = isBookingOwner(getCustomerId(currentUser), booking);
  const successColor = theme.palette.common.green[500];
  const errorColor = theme.palette.common.red[500];

  if (status === BookingStatus.requested && bookingRequestExpires(booking) && isOwner) {
    return theme.palette.common.orange[800];
  }

  if (status === BookingStatus.requested && bookingRequestExpiresSoon(booking)) {
    return theme.palette.common.orange[800];
  }

  const styleMap = {
    [BookingStatus.requested]: theme.palette.common.blue[500],
    [BookingStatus.confirmed]: successColor,
    [BookingStatus.completed]: successColor,
    [BookingStatus.rejected]: errorColor,
    [BookingStatus.cancelled]: errorColor,
    [BookingStatus.expired]: errorColor,
  };

  return styleMap[status];
};
