import PropTypes from 'prop-types';

const primaryInfo = PropTypes.shape({
  make: PropTypes.string,
  model: PropTypes.string,
  badge: PropTypes.string,
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  registrationNumber: PropTypes.string,
  odometer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

const deliveryInfo = PropTypes.shape({
  deliveryAvailable: PropTypes.bool,
  deliveryRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  deliveryPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export default {
  primaryInfo,
  deliveryInfo,
};
