import moment from 'moment';
import { nanoid } from 'nanoid';

import {
  AUSTRALIA_SEARCH_LOCATION,
  CURRENT_LOCATION_NAME,
  NEW_ZEALAND_SEARCH_LOCATION,
  Region,
} from '../enums';

const getAuEarningCalculatorLocations = () => [
  { name: 'Sydney', value: 24100 },
  { name: 'Melbourne', value: 23000 },
  { name: 'Perth', value: 21320 },
  { name: 'Brisbane', value: 20780 },
  { name: 'Gold Coast', value: 16000 },
  { name: 'Hobart', value: 20160 },
  { name: 'Adelaide', value: 18190 },
  { name: 'Canberra', value: 17660 },
  { name: 'Byron Bay', value: 12110 },
  { name: 'Cairns', value: 14490 },
];

const getNzEarningCalculatorLocations = () => [
  { name: 'Auckland', value: 24100 },
  { name: 'Christchurch', value: 24100 },
  { name: 'Wellington', value: 24100 },
];

export const AUSTRALIA_PHONE_CODE = '+61';
export const NEW_ZEALAND_PHONE_CODE = '+64';

export const defaultAustraliaLocation = {
  latitude: -25.274398,
  longitude: 133.775136,
  radius: 3000000,
  name: AUSTRALIA_SEARCH_LOCATION,
  zoom: 5,
};

export const defaultNewZealandLocation = {
  latitude: -41.078943,
  longitude: 174.75277,
  radius: 1000000,
  name: NEW_ZEALAND_SEARCH_LOCATION,
  zoom: 6,
};

export const CURRENT_LOCATION = {
  _id: nanoid(),
  name: CURRENT_LOCATION_NAME,
  type: 'current',
  icon: 'current',
  radius: 5000,
};

export const getDefaultSearchLocation = (region) => {
  if (region.id === Region.newZealand.id) {
    return defaultNewZealandLocation;
  }

  return defaultAustraliaLocation;
};

export const getLegalEntity = (region) => {
  if (region.id === Region.newZealand.id) {
    return `© evee New Zealand Ltd ${moment().year()} | NZBN 9429050696703 | New Zealand`;
  }

  return `© evee Australia Pty Ltd ${moment().year()} | ABN 40 609 872 031 | Australia`;
};

export const getDefaultPhoneCode = (region) => {
  if (region.id === Region.newZealand.id) {
    return NEW_ZEALAND_PHONE_CODE;
  }

  return AUSTRALIA_PHONE_CODE;
};

export const getEarningCalculatorLocations = (region) => {
  if (region.id === Region.newZealand.id) {
    return getNzEarningCalculatorLocations();
  }

  return getAuEarningCalculatorLocations();
};

export const getEarningCalculatorDefaultLocation = (region) => {
  if (region.id === Region.newZealand.id) {
    return 'Auckland';
  }

  return 'Sydney';
};

export const getTopCites = (region) => {
  if (region.id === Region.newZealand.id) {
    return ['Auckland', 'Wellington', 'Sydney', 'Melbourne', 'Brisbane'];
  }

  return ['Sydney', 'Melbourne', 'Brisbane', 'Perth', 'Hobart'];
};

export const getBusinessNumberAbbreviation = (region) => {
  if (region.id === Region.newZealand.id) {
    return 'NZBN';
  }

  return 'ABN';
};

export const getHomeUrl = (region) => `https://${region.domain}`;

export const getSupportUrl = (region) => `https://${region.supportDomain}`;

export const getCurrentPosition = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (pos) => resolve(pos.coords),
      () => reject(new Error('Current location is unavailable')),
      {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0,
      },
    );
  });
