import React from 'react';

import { SvgIcon } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const Car = ({ ...rest }) => {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 61 24" {...rest}>
      <path
        d="M60.0449 6.00887L53.2536 5.03869C52.0122 4.27715 50.7291 3.59906 49.4042 3.00444C45.0332 1.02234 40.2762 0 35.4774 0H34.2986C28.634 0 23.0528 1.42919 18.0663 4.14153L14.6342 6.00887C10.6283 6.0506 6.69537 7.11467 3.21107 9.10719C1.79231 9.9209 0.916016 11.4231 0.916016 13.0609V18.9968C0.916016 19.5497 1.36459 19.9983 1.91749 19.9983H5.27662C6.37198 23.1175 9.78326 24.7553 12.892 23.6599C14.6029 23.0549 15.959 21.7091 16.5537 19.9983H43.2702C44.3655 23.1175 47.7768 24.7553 50.8856 23.6599C52.5964 23.0549 53.9526 21.7091 54.5472 19.9983H54.9019C55.0271 19.9983 55.1523 19.9774 55.2775 19.9252L58.5114 18.6212C59.9615 18.0475 60.9003 16.6496 60.9003 15.0952V6.99992C60.9003 6.49918 60.5352 6.0819 60.0449 6.00887ZM14.8324 18.7986C14.3942 20.958 12.2765 22.3559 10.1171 21.9178C7.95765 21.4796 6.55976 19.3619 6.9979 17.2025C7.43605 15.043 9.55376 13.6451 11.7132 14.0833C13.5701 14.4588 14.9158 16.1071 14.9158 18.0057C14.9158 18.2665 14.8845 18.5273 14.8324 18.7986ZM32.9111 5.99844H18.8383L19.0261 5.89412C23.3032 3.56777 28.0498 2.25333 32.9111 2.04469V5.99844ZM34.9141 5.99844V2.00296H35.4774C39.5042 2.00296 43.4997 2.7645 47.2448 4.25628L45.5026 5.99844H34.9141ZM52.8259 18.7986C52.3878 20.958 50.2701 22.3559 48.1106 21.9178C45.9512 21.4796 44.5533 19.3619 44.9915 17.2025C45.4296 15.043 47.5473 13.6451 49.7067 14.0833C51.5637 14.4588 52.9094 16.1071 52.9094 18.0057C52.9094 18.2665 52.8781 18.5273 52.8259 18.7986Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M32.9121 17.9958H16.9093C16.9093 14.6784 14.2283 11.9974 10.9109 11.9974C7.59349 11.9974 4.91245 14.6784 4.91245 17.9958H2.91992V13.0615C2.91992 12.1435 3.41023 11.2985 4.20307 10.8499C7.4683 8.98256 11.1613 8.00195 14.9168 8.00195H32.9121V17.9958Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M25.9121 8.99219H29.9076V10.9951H25.9121V8.99219Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M58.9085 8.99271H55.904V10.9957H58.9085V15.0954C58.9085 15.8361 58.4599 16.4933 57.7818 16.7646L54.913 17.9121C54.913 17.8182 54.9026 17.7243 54.8921 17.6409C54.8817 17.547 54.8817 17.4427 54.8713 17.3383C54.8608 17.234 54.84 17.1401 54.8295 17.0358C54.8087 16.9315 54.7982 16.8376 54.7774 16.7437C54.7565 16.6498 54.7252 16.5559 54.7043 16.4516C54.6835 16.3577 54.6626 16.2638 54.6313 16.1699C54.6 16.0761 54.5687 15.9926 54.527 15.8987C54.4957 15.8048 54.4644 15.7109 54.4227 15.6275C54.3914 15.5336 54.3392 15.4606 54.2975 15.3667C54.2558 15.2832 54.214 15.1893 54.1723 15.1059C54.1201 15.0224 54.068 14.9494 54.0263 14.8659C53.9845 14.7825 53.9219 14.699 53.8698 14.6156C53.8176 14.5321 53.755 14.4695 53.7029 14.3965C53.6507 14.3235 53.5881 14.24 53.5255 14.1566C53.4629 14.0835 53.4003 14.0209 53.3377 13.9584C53.2752 13.8958 53.2021 13.8123 53.1395 13.7393C53.0665 13.6663 53.0039 13.6141 52.9309 13.5515C52.8579 13.4889 52.7848 13.4159 52.7118 13.3533C52.6388 13.2907 52.5553 13.2385 52.4823 13.1864C52.4093 13.1342 52.3258 13.0716 52.2528 13.0195C52.1694 12.9673 52.0859 12.9152 51.992 12.863C51.9086 12.8108 51.8355 12.7587 51.7521 12.7169C51.6686 12.6752 51.5747 12.6335 51.4808 12.5813C51.387 12.5396 51.3139 12.4979 51.2305 12.4666C51.147 12.4353 51.0323 12.3936 50.9279 12.3518C50.8236 12.3205 50.761 12.2892 50.6776 12.2579C50.5628 12.2266 50.4481 12.1953 50.3333 12.164C50.2603 12.1432 50.1873 12.1223 50.1038 12.1015C49.9786 12.0806 49.8534 12.0597 49.7283 12.0493C49.6552 12.0389 49.5822 12.0284 49.5092 12.018C49.311 11.9971 49.1128 11.9867 48.9041 11.9867C45.5972 11.9867 42.9057 14.6782 42.9057 17.9851H34.9043V7.99123H45.8997C46.1605 7.99123 46.4213 7.88691 46.6091 7.69914L49.2066 5.10156C50.2916 5.62316 51.3557 6.19692 52.378 6.83328C52.4927 6.9063 52.6284 6.95846 52.764 6.97933L58.9085 7.85562V8.99271Z"
        fill={theme.palette.secondary.main}
      />
    </SvgIcon>
  );
};
Car.displayName = 'CarIcon';
Car.muiName = 'SvgIcon';

export default Car;
